<template>
  <div class="d-flex flex-column h-100">
    <Nav />
  </div>
</template>

<script>
import Nav from "../components/Nav";
export default {
  name: "Layout",
  components: {
    Nav
  }
};
</script>
